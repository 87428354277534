import styled from "styled-components";

export const ShaderGradient = styled.div`
  position: fixed;
  width: 100vw;
  top: 0;
  left: 0;
  height: 144px;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);
  z-index: 5;
`;
