import React, { FC } from "react";

import { useMediaQuery } from "react-responsive";

export interface MediaQueriesProps {
  children: React.ReactElement;
}

export const Desktop: FC<MediaQueriesProps> = (props) => {
  const { children } = props;
  const isDesktop = useMediaQuery({ minWidth: 992 });
  return isDesktop ? children : null;
};

export const Mobile: FC<MediaQueriesProps> = (props) => {
  const { children } = props;
  const isMobile = useMediaQuery({ maxWidth: 992 });
  return isMobile ? children : null;
};
