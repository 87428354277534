import React, { FC } from "react";

import { ShaderGradient } from "./Shader.styles";

export interface ShaderProps {}

const Shader: FC<ShaderProps> = ({ ...rest }) => {
  return <ShaderGradient {...rest} />;
};
export default Shader;
